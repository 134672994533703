import { z } from 'zod';

const envSchema = z.object({
  HOST: z.string().optional(),
  NODE_ENV: z
    .union([z.literal('development'), z.literal('production')])
    .default('development'),
  NEXT_PUBLIC_API_HOST: z.string().url(),
  NEXT_PUBLIC_API_TOKEN: z.string(),
  NEXT_PUBLIC_GTM_KEY: z.string().optional(),
  NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
  NEXT_PUBLIC_POSTHOG_HOST: z.string().url().optional(),
  NEXT_PUBLIC_HUBSPOT_FORM_API_ENDPOINT: z.string().optional(),
  NEXT_PUBLIC_HUBSPOT_UPLOAD_API_ENDPOINT: z.string().optional(),
  NEXT_PUBLIC_HUBSPOT_PRIVATE_APP_KEY: z.string().optional(),
});

export type Environment = z.infer<typeof envSchema>;

export const env = envSchema.parse({
  HOST: process.env.HOST,
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_API_HOST: process.env.NEXT_PUBLIC_API_HOST,
  NEXT_PUBLIC_API_TOKEN: process.env.NEXT_PUBLIC_API_TOKEN,
  NEXT_PUBLIC_GTM_KEY: process.env.NEXT_PUBLIC_GTM_KEY,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  NEXT_PUBLIC_HUBSPOT_FORM_API_ENDPOINT:
    process.env.NEXT_PUBLIC_HUBSPOT_FORM_API_ENDPOINT,
  NEXT_PUBLIC_HUBSPOT_UPLOAD_API_ENDPOINT:
    process.env.NEXT_PUBLIC_HUBSPOT_UPLOAD_API_ENDPOINT,
  NEXT_PUBLIC_HUBSPOT_PRIVATE_APP_KEY:
    process.env.NEXT_PUBLIC_HUBSPOT_PRIVATE_APP_KEY,
});
