import { IconButton } from '@/components/commonComponents/iconButton/iconButton';
import { formatBytes } from 'helpers/format-bytes';

import { Typography } from 'components/typography';

import { Icon } from '../../icon';
import { InputLabel } from '../inputLabel';
import styles from './styles.module.scss';

type UploadedFile = {
  fileName: string;
  fileSize: number;
  removeFile: () => void;
};

type UploadedFilesProps = {
  files: UploadedFile[];
};

export const UploadedFiles = ({ files }: UploadedFilesProps) => {
  return (
    <div className={styles.uploadedFilesWrapper}>
      <InputLabel fieldId="uploaded-files">Uploads:</InputLabel>
      {files.map((file, index) => (
        <div key={index} className={styles.uploadedFileItem}>
          <div className={styles.uploadedFileType}>
            <Icon name="File" />
          </div>
          <div className={styles.uploadedFileDesc}>
            <Typography variant="input-label" tag="span">
              {file.fileName}
            </Typography>
            <Typography variant="input-support" tag="span" color="muted">
              {formatBytes(file.fileSize) ?? 0}
            </Typography>
          </div>
          <IconButton
            style="muted"
            shape="circle"
            ghost
            icon="Trash"
            onClick={file.removeFile}
            className={styles.removeFileButton}
            withoutPadding={true}
          />
        </div>
      ))}
    </div>
  );
};
