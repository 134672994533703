import { Illustration } from '@/components/internalComponents/illustration/illustration';
import classNames from 'classnames';
import { DragEvent, ReactNode, useRef, useState } from 'react';

import { Typography } from 'components/typography';

import { InputLabel } from '../inputLabel';
import styles from './styles.module.scss';

interface UploadFieldProps {
  className?: string;
  label?: string | ReactNode;
  name: string;
  required?: boolean;
  value?: File | null;
  hasError?: boolean;
  onChange?: (file: File | null) => void;
}

export const UploadField = ({
  className,
  label,
  name,
  required,
  hasError,
  onChange,
}: UploadFieldProps) => {
  const elementId = `field-${name}`;
  const dropRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const dropHandler = (event: DragEvent) => {
    event.preventDefault();
    setIsDragging(false);

    for (const item of event.dataTransfer.items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        onChange?.(file);
      }
    }
  };

  const dragOverHandler = (event: DragEvent) => {
    event.preventDefault();
  };

  const dragLeaveHandler = (event: DragEvent) => {
    if (event.target === dropRef.current) {
      setIsDragging(false);
    }
  };

  const clickHandler = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    inputRef.current?.click();
  };

  const changeHandler = () => {
    if (inputRef.current?.files?.[0]) {
      const file = inputRef.current.files[0];
      onChange?.(file);
    }
  };

  return (
    <div className={classNames(className, styles.uploadFieldWrapper)}>
      <InputLabel fieldId={elementId}>
        {label} {required ? '*' : ''}
      </InputLabel>
      <div
        ref={dropRef}
        className={classNames(
          styles.uploadField,
          hasError ? styles.error : '',
          isDragging ? styles.hovering : ''
        )}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={dragLeaveHandler}
        onDragOver={dragOverHandler}
        onDrop={dropHandler}
      >
        <input
          className={styles.fileInput}
          id={elementId}
          type="file"
          required={required}
          name={name}
          ref={inputRef}
          onChange={changeHandler}
          placeholder="Choose a file"
        />
        <div className={styles.dragContent}>
          <Illustration
            name="UploadDocument"
            className={classNames(
              styles.illustration,
              hasError ? styles.error : ''
            )}
          />
          <button className={styles.uploadButton} onClick={clickHandler}>
            <Typography
              variant="input"
              tag="span"
              color={hasError ? 'error' : 'secondary'}
            >
              <span className={styles.underline}>Bestand uploaden</span>
              <span className={styles.dragToText}>of sleep hiernaartoe</span>
            </Typography>
            <Typography
              variant="input"
              color={hasError ? 'error' : 'muted'}
              tag="span"
            >
              pdf, doc, docx, maximaal 4MB
            </Typography>
          </button>
        </div>
      </div>
    </div>
  );
};
