import { Button } from '@/components/commonComponents/button/button';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { Typography } from 'components/typography';

import { Icon } from '../icon';
import styles from './styles.module.scss';

type FormSuccesOverlayProps = {
  title: string;
  message: string;
  onClose: () => void;
};

export const FormSuccesOverlay = ({
  title,
  message,
  onClose,
}: FormSuccesOverlayProps) => {
  return (
    <motion.div
      className={classNames(styles.formSuccesOverlay)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, delay: 0.3, ease: 'easeIn' },
      }}
    >
      <motion.div
        className={styles.formSuccesContent}
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: 10,
          opacity: 0,
          transition: { duration: 0.3, delay: 0, ease: 'easeIn' },
        }}
        transition={{ duration: 0.4, delay: 0.25, ease: 'easeOut' }}
      >
        <div className={styles.formSuccessIcon}>
          <Icon name="Check" />
        </div>
        <Typography variant="heading3-regular-alt" tag="h2">
          {title}
        </Typography>
        <Typography variant="paragraph-reg" tag="p">
          {message}
        </Typography>
      </motion.div>
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: { duration: 0.3, delay: 0.5, ease: 'easeOut' },
        }}
        exit={{
          scale: 0.8,
          opacity: 0,
          transition: { duration: 0.3, delay: 0.15, ease: 'easeIn' },
        }}
      >
        <Button onClick={onClose} style="underline">
          Sluit dit bericht
        </Button>
      </motion.div>
    </motion.div>
  );
};
