import classNames from 'classnames';
import { ReactNode } from 'react';

import { AbstractButton } from '../../internalComponents/abstractButton/abstractButton';
import { Icon, Icons } from '../../internalComponents/icon';
import styles from './iconButton.module.scss';

export interface IconButtonProps {
  className?: string;
  style: 'primary' | 'secondary' | 'tertiary' | 'muted';
  surface?: 'neutral' | 'inverted';
  shape?: 'square' | 'circle';
  ghost?: boolean;
  icon?: keyof typeof Icons;
  onClick?: () => void;
  href?: string;
  children?: ReactNode;
  withoutPadding?: boolean;
}

export const IconButton = ({
  className,
  style,
  surface = 'neutral',
  shape = 'square',
  ghost,
  icon,
  children,
  withoutPadding,
  ...props
}: IconButtonProps) => {
  return (
    <AbstractButton
      {...props}
      className={classNames(
        styles.iconButton,
        styles[style],
        styles[surface],
        styles[shape],
        ghost && styles.ghost,
        withoutPadding && styles.withoutPadding,
        className
      )}
    >
      {icon ? <Icon name={icon} className={styles.icon} /> : children}
    </AbstractButton>
  );
};
