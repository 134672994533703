import { Icon, Icons } from '@/components/internalComponents/icon';
import classNames from 'classnames';
import { HTMLAttributes, ReactNode, useEffect, useState } from 'react';

import { InputError } from '../inputError';
import { InputLabel } from '../inputLabel';
import styles from './styles.module.scss';

interface TextFieldProps
  extends Omit<
    HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    'onChange'
  > {
  className?: string;
  label: string | ReactNode;
  name: string;
  type?: string;
  required?: boolean;
  icon?: keyof typeof Icons;
  value?: string;
  multiline?: boolean;
  rows?: number;
  error?: string;
  onChange?: (value: string) => void;
}

export const TextField = ({
  className,
  label,
  name,
  type = 'text',
  required,
  icon,
  value,
  multiline,
  rows = 3,
  error,
  onChange,
  ...props
}: TextFieldProps) => {
  const elementId = `field-${name}`;
  const [focused, setFocused] = useState<boolean>(false);

  const [textAreaText, setTextAreaText] = useState(' ');
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <div className={styles.textFieldWrapper}>
      <div
        className={classNames(
          styles.textField,
          focused ? styles.focused : '',
          error ? styles.error : '',
          className ?? ''
        )}
      >
        <InputLabel
          className={classNames(
            styles.label,
            focused && styles.active,
            internalValue?.length && !focused ? styles.filled : '',
            error && styles.error,
            focused && styles.focused,
            icon && styles.hasIcon
          )}
          fieldId={elementId}
        >
          {label} {required ? '*' : ''}
        </InputLabel>
        {icon && <Icon name={icon} className={styles.icon} />}
        <div
          className={`${styles.inputContainer} ${
            multiline ? styles.multiline : ''
          }`}
          data-replicatedvalue={textAreaText}
        >
          {multiline ? (
            <textarea
              {...props}
              id={elementId}
              className={classNames(styles.input, styles.multiline)}
              value={value}
              name={name}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onInput={(event) => {
                onChange?.(event.currentTarget.value);
                setInternalValue(event.currentTarget.value);
              }}
              rows={rows}
            />
          ) : (
            <input
              {...props}
              id={elementId}
              className={classNames(styles.input)}
              type={type}
              value={value}
              name={name}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onInput={(event) => {
                onChange?.(event.currentTarget.value);
                setInternalValue(event.currentTarget.value);
                setTextAreaText(event.currentTarget.value);
              }}
            />
          )}
        </div>
      </div>
      {error && <InputError>{error}</InputError>}
    </div>
  );
};
