import classNames from 'classnames';
import { ReactNode } from 'react';

import { Typography } from 'components/typography';

import styles from './styles.module.scss';

export type ListItemProps = {
  heading: string;
  subheading?: string;
  border?: boolean;
  className?: string;
  leadingAddon?: ReactNode;
  trailingAddon?: ReactNode;
  onClick?: () => void;
};

export const ListItem = ({
  heading,
  subheading,
  border,
  className,
  leadingAddon,
  trailingAddon,
  onClick,
}: ListItemProps) => {
  return (
    <li
      className={classNames(
        styles.listItem,
        border && styles.border,
        onClick && styles.clickable,
        className
      )}
      onClick={onClick}
    >
      {leadingAddon && (
        <div className={styles.leadingAddon}>{leadingAddon}</div>
      )}
      <div className={styles.content}>
        <Typography variant="list-item-heading" tag="span">
          {heading}
        </Typography>
        {subheading && (
          <Typography variant="list-item-subheading" tag="span" color="muted">
            {subheading}
          </Typography>
        )}
      </div>
      {trailingAddon && (
        <div className={styles.trailingAddon}>{trailingAddon}</div>
      )}
    </li>
  );
};
