import classNames from 'classnames';
import { ReactNode } from 'react';

import { Typography } from 'components/typography';

import styles from './styles.module.scss';

interface InputLabelProps {
  className?: string;
  active?: boolean;
  focused?: boolean;
  hasIcon?: boolean;
  fieldId: string;
  children: ReactNode;
}

export const InputLabel = ({
  className,
  active,
  focused,
  hasIcon,
  fieldId,
  children,
}: InputLabelProps) => {
  return (
    <Typography
      className={classNames(
        styles.inputLabel,
        active && styles.active,
        focused && styles.focused,
        hasIcon && styles.hasIcon,
        className
      )}
      tag="label"
      variant="input-label"
      htmlFor={fieldId}
    >
      {children}
    </Typography>
  );
};
