import classNames from 'classnames';
import { ReactNode } from 'react';

import { Typography } from 'components/typography';

import styles from './styles.module.scss';

interface InputErrorProps {
  className?: string;
  children: ReactNode;
}

export const InputError = ({ className, children }: InputErrorProps) => {
  return (
    <div className={classNames(styles.errorWrapper, className)}>
      <Typography variant="input-support" color="error" tag="span">
        {children}
      </Typography>
    </div>
  );
};
