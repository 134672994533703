'use client';
import { UploadedFiles } from '@/components/internalComponents/form/uploadedFiles';
import { FormSuccesOverlay } from '@/components/internalComponents/formSuccesOverlay';
import { AnimatePresence } from 'framer-motion';
import { env } from 'helpers/env';
import { scrollTo } from 'helpers/scrollTo';
import { FormEvent, useState } from 'react';

import { Button } from '../commonComponents/button/button';
import { TextField } from '../internalComponents/form/textField';
import { UploadField } from '../internalComponents/form/uploadfield';
import styles from './styles.module.scss';

const VACANCY_FORM = 'vacancy-form' as const;

export interface FormProps {
  vacancyId: number;
}

export const VacancyForm = ({
  vacancyId,
}: FormProps): React.ReactElement<HTMLFormElement> => {
  const [formState, setFormState] = useState<'default' | 'sending' | 'sent'>(
    'default'
  );

  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [motivation, setMotivation] = useState<string>('');
  const [resume, setResume] = useState<File | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const submitForm = (event: FormEvent) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget as HTMLFormElement);
    formData.append('vacancyId', `${vacancyId}`);

    if (resume) {
      formData.delete('resume');
      formData.append('resume', resume);
    }

    setFormState('sending');
    fetch(`${env.NEXT_PUBLIC_API_HOST}/api/vacancy/apply`, {
      method: 'POST',
      body: formData,
    }).then(() => {
      setFormState('default');
      setShowSuccess(true);
      scrollTo(VACANCY_FORM, -200);
    });
  };

  const handleCloseSuccessOverlay = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setPhone('');
    setMotivation('');

    setResume(null);
    setShowSuccess(false);
  };

  return (
    <form className={styles.form} onSubmit={submitForm} id={VACANCY_FORM}>
      <div className={styles.name}>
        <TextField
          label="Voornaam"
          name="firstname"
          type="text"
          required
          value={firstname}
          onChange={setFirstname}
        />

        <TextField
          label="Achternaam"
          name="lastname"
          type="text"
          required
          value={lastname}
          onChange={setLastname}
        />
      </div>

      <TextField
        label="Email"
        name="email"
        type="email"
        required
        value={email}
        onChange={setEmail}
      />

      <TextField
        label="Telefoonnummer"
        name="phone"
        type="tel"
        required
        value={phone}
        onChange={setPhone}
      />

      <TextField
        label="Motivatie"
        name="motivation"
        type="text"
        required
        value={motivation}
        onChange={setMotivation}
        multiline
        rows={8}
      />

      <UploadField
        label="CV"
        name="resume"
        required
        value={resume}
        onChange={setResume}
      />
      {resume && (
        <UploadedFiles
          files={[
            {
              fileName: resume.name,
              fileSize: resume.size,
              removeFile: () => setResume(null),
            },
          ]}
        />
      )}

      <Button
        style="primary"
        type="submit"
        className={styles.submit}
        disabled={formState !== 'default'}
        icon="ArrowRight"
      >
        {formState === 'sending' ? 'Versturen . . .' : 'Verstuur'}
      </Button>
      <AnimatePresence>
        {showSuccess && (
          <FormSuccesOverlay
            title="Message received!"
            message="Bedankt voor je berichtje! We proberen uiterlijk binnen 1 werkdag te reageren."
            onClose={handleCloseSuccessOverlay}
          />
        )}
      </AnimatePresence>
    </form>
  );
};
